"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.expandPath = void 0;

var _trimEnd2 = _interopRequireDefault(require("lodash/trimEnd"));

var _get2 = _interopRequireDefault(require("lodash/get"));

var _debounce2 = _interopRequireDefault(require("lodash/debounce"));

var _last2 = _interopRequireDefault(require("lodash/last"));

var _isEmpty2 = _interopRequireDefault(require("lodash/isEmpty"));

var _find2 = _interopRequireDefault(require("lodash/find"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactImmutableProptypes = _interopRequireDefault(require("react-immutable-proptypes"));

var _reactSelect = require("react-select");

var _immutable = require("immutable");

var _netlifyCmsUiDefault = require("netlify-cms-ui-default");

var _netlifyCmsLibWidgets = require("netlify-cms-lib-widgets");

var _core = require("@emotion/core");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function optionToString(option) {
  return option && option.value ? option.value : '';
}

function convertToOption(raw) {
  if (typeof raw === 'string') {
    return {
      label: raw,
      value: raw
    };
  }

  return _immutable.Map.isMap(raw) ? raw.toJS() : raw;
}

function getSelectedValue({
  value,
  options,
  isMultiple
}) {
  if (isMultiple) {
    const selectedOptions = _immutable.List.isList(value) ? value.toJS() : value;

    if (!selectedOptions || !Array.isArray(selectedOptions)) {
      return null;
    }

    return selectedOptions.map(i => options.find(o => o.value === (i.value || i))).filter(Boolean).map(convertToOption);
  } else {
    return (0, _find2.default)(options, ['value', value]) || null;
  }
}

const expandPath = ({
  data,
  path,
  paths = []
}) => {
  if (path.endsWith('.*')) {
    path = path + '.';
  }

  const sep = '.*.';
  const parts = path.split(sep);

  if (parts.length === 1) {
    paths.push(path);
  } else {
    const partialPath = parts[0];
    const value = (0, _get2.default)(data, partialPath);

    if (Array.isArray(value)) {
      value.forEach((v, index) => {
        expandPath({
          data,
          path: (0, _trimEnd2.default)(`${partialPath}.${index}.${parts.slice(1).join(sep)}`, '.'),
          paths
        });
      });
    }
  }

  return paths;
};

exports.expandPath = expandPath;

class RelationControl extends _react.default.Component {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "didInitialSearch", false);

    _defineProperty(this, "handleChange", selectedOption => {
      const {
        onChange,
        field
      } = this.props;
      let value;
      let metadata;

      if (Array.isArray(selectedOption)) {
        value = selectedOption.map(optionToString);
        metadata = !(0, _isEmpty2.default)(selectedOption) && {
          [field.get('name')]: {
            [field.get('collection')]: {
              [(0, _last2.default)(value)]: (0, _last2.default)(selectedOption).data
            }
          }
        } || {};
        onChange((0, _immutable.fromJS)(value), metadata);
      } else {
        value = optionToString(selectedOption);
        metadata = selectedOption && {
          [field.get('name')]: {
            [field.get('collection')]: {
              [value]: selectedOption.data
            }
          }
        };
        onChange(value, metadata);
      }
    });

    _defineProperty(this, "parseNestedFields", (hit, field) => {
      const templateVars = _netlifyCmsLibWidgets.stringTemplate.extractTemplateVars(field); // return non template fields as is


      if (templateVars.length <= 0) {
        return (0, _get2.default)(hit.data, field);
      }

      const data = _netlifyCmsLibWidgets.stringTemplate.addFileTemplateFields(hit.path, (0, _immutable.fromJS)(hit.data));

      const value = _netlifyCmsLibWidgets.stringTemplate.compileStringTemplate(field, null, hit.slug, data);

      return value;
    });

    _defineProperty(this, "parseHitOptions", hits => {
      const {
        field
      } = this.props;
      const valueField = field.get('valueField');
      const displayField = field.get('displayFields') || (0, _immutable.List)([field.get('valueField')]);
      const options = hits.reduce((acc, hit) => {
        const valuesPaths = expandPath({
          data: hit.data,
          path: valueField
        });

        for (let i = 0; i < valuesPaths.length; i++) {
          const label = displayField.toJS().map(key => {
            const displayPaths = expandPath({
              data: hit.data,
              path: key
            });
            return this.parseNestedFields(hit, displayPaths[i] || displayPaths[0]);
          }).join(' ');
          const value = this.parseNestedFields(hit, valuesPaths[i]);
          acc.push({
            data: hit.data,
            value,
            label
          });
        }

        return acc;
      }, []);
      return options;
    });

    _defineProperty(this, "loadOptions", (0, _debounce2.default)((term, callback) => {
      const {
        field,
        query,
        forID
      } = this.props;
      const collection = field.get('collection');
      const searchFields = field.get('searchFields');
      const optionsLength = field.get('optionsLength') || 20;
      const searchFieldsArray = _immutable.List.isList(searchFields) ? searchFields.toJS() : [searchFields];
      const file = field.get('file');
      const queryPromise = file ? query(forID, collection, ['slug'], file) : query(forID, collection, searchFieldsArray, term);
      queryPromise.then(({
        payload
      }) => {
        let options = payload.response && payload.response.hits ? this.parseHitOptions(payload.response.hits) : [];

        if (!this.allOptions && !term) {
          this.allOptions = options;
        }

        if (!term) {
          options = options.slice(0, optionsLength);
        }

        callback(options);
      });
    }, 500));
  }

  shouldComponentUpdate(nextProps) {
    return this.props.value !== nextProps.value || this.props.hasActiveStyle !== nextProps.hasActiveStyle || this.props.queryHits !== nextProps.queryHits;
  }

  componentDidUpdate(prevProps) {
    /**
     * Load extra post data into the store after first query.
     */
    if (this.didInitialSearch) return;
    const {
      value,
      field,
      forID,
      queryHits,
      onChange
    } = this.props;

    if (queryHits !== prevProps.queryHits && queryHits.get(forID)) {
      this.didInitialSearch = true;
      const valueField = field.get('valueField');
      const hits = queryHits.get(forID);

      if (value) {
        const listValue = _immutable.List.isList(value) ? value : (0, _immutable.List)([value]);
        listValue.forEach(val => {
          const hit = hits.find(hit => this.parseNestedFields(hit, valueField) === val);

          if (hit) {
            onChange(value, {
              [field.get('name')]: {
                [field.get('collection')]: {
                  [val]: hit.data
                }
              }
            });
          }
        });
      }
    }
  }

  render() {
    const {
      value,
      field,
      forID,
      classNameWrapper,
      setActiveStyle,
      setInactiveStyle,
      queryHits
    } = this.props;
    const isMultiple = field.get('multiple', false);
    const isClearable = !field.get('required', true) || isMultiple;
    const hits = queryHits.get(forID, []);
    const options = this.allOptions || this.parseHitOptions(hits);
    const selectedValue = getSelectedValue({
      options,
      value,
      isMultiple
    });
    return (0, _core.jsx)(_reactSelect.Async, {
      value: selectedValue,
      inputId: forID,
      defaultOptions: true,
      loadOptions: this.loadOptions,
      onChange: this.handleChange,
      className: classNameWrapper,
      onFocus: setActiveStyle,
      onBlur: setInactiveStyle,
      styles: _netlifyCmsUiDefault.reactSelectStyles,
      isMulti: isMultiple,
      isClearable: isClearable,
      placeholder: ""
    });
  }

}

exports.default = RelationControl;

_defineProperty(RelationControl, "propTypes", {
  onChange: _propTypes.default.func.isRequired,
  forID: _propTypes.default.string.isRequired,
  value: _propTypes.default.node,
  field: _reactImmutableProptypes.default.map,
  fetchID: _propTypes.default.string,
  query: _propTypes.default.func.isRequired,
  queryHits: _propTypes.default.oneOfType([_propTypes.default.array, _propTypes.default.object]),
  classNameWrapper: _propTypes.default.string.isRequired,
  setActiveStyle: _propTypes.default.func.isRequired,
  setInactiveStyle: _propTypes.default.func.isRequired
});