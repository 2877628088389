"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getEditorComponents = exports.getEditorControl = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactImmutableProptypes = _interopRequireDefault(require("react-immutable-proptypes"));

var _RawEditor = _interopRequireDefault(require("./RawEditor"));

var _VisualEditor = _interopRequireDefault(require("./VisualEditor"));

var _core = require("@emotion/core");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const MODE_STORAGE_KEY = 'cms.md-mode'; // TODO: passing the editorControl and components like this is horrible, should
// be handled through Redux and a separate registry store for instances

let editorControl;

let _getEditorComponents = () => [];

const getEditorControl = () => editorControl;

exports.getEditorControl = getEditorControl;

const getEditorComponents = () => _getEditorComponents();

exports.getEditorComponents = getEditorComponents;

class MarkdownControl extends _react.default.Component {
  constructor(props) {
    super(props);

    _defineProperty(this, "handleMode", mode => {
      this.setState({
        mode,
        pendingFocus: true
      });
      localStorage.setItem(MODE_STORAGE_KEY, mode);
    });

    _defineProperty(this, "processRef", ref => this.ref = ref);

    _defineProperty(this, "setFocusReceived", () => {
      this.setState({
        pendingFocus: false
      });
    });

    editorControl = props.editorControl;
    _getEditorComponents = props.getEditorComponents;
    this.state = {
      mode: localStorage.getItem(MODE_STORAGE_KEY) || 'visual',
      pendingFocus: false
    };
  }

  render() {
    const {
      onChange,
      onAddAsset,
      getAsset,
      value,
      classNameWrapper,
      field,
      getEditorComponents,
      resolveWidget,
      t
    } = this.props;
    const {
      mode,
      pendingFocus
    } = this.state;
    const visualEditor = (0, _core.jsx)("div", {
      className: "cms-editor-visual",
      ref: this.processRef
    }, (0, _core.jsx)(_VisualEditor.default, {
      onChange: onChange,
      onAddAsset: onAddAsset,
      onMode: this.handleMode,
      getAsset: getAsset,
      className: classNameWrapper,
      value: value,
      field: field,
      getEditorComponents: getEditorComponents,
      resolveWidget: resolveWidget,
      pendingFocus: pendingFocus && this.setFocusReceived,
      t: t
    }));
    const rawEditor = (0, _core.jsx)("div", {
      className: "cms-editor-raw",
      ref: this.processRef
    }, (0, _core.jsx)(_RawEditor.default, {
      onChange: onChange,
      onAddAsset: onAddAsset,
      onMode: this.handleMode,
      getAsset: getAsset,
      className: classNameWrapper,
      value: value,
      field: field,
      pendingFocus: pendingFocus && this.setFocusReceived,
      t: t
    }));
    return mode === 'visual' ? visualEditor : rawEditor;
  }

}

exports.default = MarkdownControl;

_defineProperty(MarkdownControl, "propTypes", {
  onChange: _propTypes.default.func.isRequired,
  onAddAsset: _propTypes.default.func.isRequired,
  getAsset: _propTypes.default.func.isRequired,
  classNameWrapper: _propTypes.default.string.isRequired,
  editorControl: _propTypes.default.func.isRequired,
  value: _propTypes.default.string,
  field: _reactImmutableProptypes.default.map.isRequired,
  getEditorComponents: _propTypes.default.func,
  t: _propTypes.default.func.isRequired
});

_defineProperty(MarkdownControl, "defaultProps", {
  value: ''
});